import {Box, HStack, VStack} from '@chakra-ui/layout';
import {BlueButton} from '@/components/Button/BlueButton';
import {GrayButton} from '@/components/Button/GrayButton';
import {CloseButton, StarIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {useCommand, useHalState} from '@synako/haligator';
import {useMe} from '@/haligator-factory';
import {useEffect} from 'react';
import {Slide, useDisclosure} from '@chakra-ui/react';
import {bottomNavHeight} from '../Navbar/BottomNav/BottomNav';

// Constantes pour le localStorage
const STORAGE_KEYS_DISMISSED_SESSION = 'analytics_dismissed_session';

// Délai avant d'afficher la popup (en ms)
const DISPLAY_DELAY = 2000;

export const ConsentementAnalytics: React.FC = () => {
  const {t} = useTranslation();
  const contact = useHalState(useMe().follow('contact'));
  const {isOpen, onClose, onOpen} = useDisclosure({defaultIsOpen: false});
  const {isAvailable, isDisabled, isSubmitting, submit:submitConsent}
    = useCommand(contact.state, 'c:consentement-analytics');
  const disable_submit = isDisabled || isSubmitting || !isAvailable;

  useEffect(() => {
    if (!contact.data) return;
    const consentement = contact.data.preferences.analytics?.consentement; 
    if (consentement === 'refuse') return;
    
    // Vérifie si l'utilisateur a déjà refusé ou fermé la popup
    const hasDismissedSession = sessionStorage.getItem(STORAGE_KEYS_DISMISSED_SESSION) === 'true';
    
    // Si l'utilisateur n'a pas fermé la popup dans cette session
    if (!hasDismissedSession) {
      // Affiche la popup après le délai
      const timer = setTimeout(() => {
        onOpen();
      }, DISPLAY_DELAY);
      
      return () => {clearTimeout(timer);};
    }
    return;
  }, [contact.data, isOpen, onOpen]);

  // Si l'utilisateur a déjà accepté, on ne montre rien
  if (contact.data && contact.data.preferences.analytics?.consentement === 'accepte')
    return null;

  // Gestion de la fermeture temporaire
  const handleDismiss = () => {
    sessionStorage.setItem(STORAGE_KEYS_DISMISSED_SESSION, 'true');
    onClose();
  };
  
  // Gestion du refus permanent
  const handleRefuse = () => {
    submitConsent({consentement: 'refuse'});
    onClose();
  };
  
  // Gestion de l'acceptation
  const handleAccept = () => {
    submitConsent({consentement: 'accepte'});
    onClose();
  };

  return (
    <Box position='relative'>
      <Slide in={isOpen}
        direction='bottom'
        style={{
          //NOTE(Nathan) Necessaire pour passer au dessus du footer
          zIndex:'2000'
        }}
      >
        <VStack
          marginBottom={{base: bottomNavHeight, md: '0'}}
          backgroundColor='blue.main'
          width='100%'
          minHeight='9em'
          justifyContent='center'
          alignContent='center'
        >
          <Box color='white'>
            <HStack px={4}>
              <StarIcon/>
              <Box>
                <HStack>
                  <Box>
                    {t('analytics.modale_consentement_titre')}
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <CloseButton
              position='absolute'
              right={3}
              top={3}
              onClick={handleDismiss}
            />
          </Box>
          <HStack>
            <BlueButton
              texte={t('global.refuser')}
              disabled={disable_submit}
              onClick={handleRefuse}/>
            <GrayButton
              texte={t('global.accepter')}
              disabled={disable_submit}
              onClick={handleAccept}/>
          </HStack>
        </VStack>
      </Slide>
    </Box>  
  );
};